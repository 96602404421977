.landing-content{
    color: #002FAB;
    padding:200px;
    padding-top:30px;
    padding-bottom:30px;
    /* background: white; */
}
@media only screen and (min-width: 1200px) {
.landing{
    background: url('../images/goldenbar.jpg');
    height: 100vh;
}
}
.buttonstoggle{
    margin-bottom: 20px;
}
.omsg{
    padding:300px !important;
    padding-top:10px !important;
    padding-bottom: 10px !important ;
}

@media only screen and (max-width: 770px) {
    .landing-content{
        color: #002FAB;
        padding:20px;
        padding-top:30px;
        padding-bottom:30px;
    }
    .omsg{
        padding:10px !important;
    }
    .buttonstoggle{
        font-size: 13px !important;
    }
}

@media only screen and (max-width: 380px) {
    .buttonstoggle{
        font-size: 12px !important;
    }
}
@media only screen and (max-width: 330px) {
    .buttonstoggle{
        font-size: 10px !important;
    }
}